import { useCVTemplates } from "@/modules/flowcase/hooks/useCVTemplates"
import { RenderNA } from "../../../../shared/components/items/RenderNA"
import { ICell } from "../../Cell"

export const TemplateCell = ({ value }: ICell) => {
  if (typeof value !== "string") {
    return <p className="text-xs text-gray-300">Invalid data</p>
  }

  const { data: templates } = useCVTemplates()
  const template = templates?.find((t) => t._id === value)

  return template ? (
    <p className="text-sm">
      {template.name}
      <span className="text-xs capitalize">{` (${template.template_type})`}</span>
    </p>
  ) : (
    <RenderNA />
  )
}
