import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import { getProfileLink } from "@/modules/flowcase/utils/links"
import { useEffect } from "react"
import { ICell } from "../../Cell"
import { useUser } from "@/modules/flowcase/hooks/useUser"
import { StatusCode } from "@/core/generated/api/enums/status_code"

export const ProfilLinkCell = (props: ICell) => {
  const cvpId = props.record?.cvp_id
  const {
    data: user,
    loading: isLoading,
    code,
  } = useUser(props.record?.type === "INTERNAL" ? cvpId : null)
  const link = getProfileLink(user)

  useEffect(() => {
    const a = document.getElementById("cv_profile")
    a?.addEventListener("click", handleLinkClick)
    return () => {
      a?.removeEventListener("click", handleLinkClick)
    }
  }, [])

  const handleLinkClick = (event: any) => {
    event.stopPropagation()
  }

  if ((code !== StatusCode.Ok || !link) && !isLoading) {
    return <p className="text-gray-200">No link</p>
  }

  if (isLoading) {
    return <Skeleton className="h-4 w-16" />
  }

  return (
    <a
      id="cv_profile"
      href={link}
      target="_blank"
      rel="noreferrer"
      className="cursor-pointer underline hover:text-black hover:underline"
    >
      View profile
    </a>
  )
}
