import { BaseRole } from "../constants/base_role"
import { ExtraRole } from "../constants/extra_role"

export class FlowcaseUser {
  user_id: string
  _id: string
  id: string
  company_id: string
  company_name: string
  company_subdomains: string[]
  company_group_ids: string[]
  email: string
  external_unique_id: string
  upn: string | null
  deactivated: boolean
  deactivated_at: boolean
  created_at: string
  updated_at: string
  role: BaseRole & ExtraRole
  roles: (BaseRole & ExtraRole)[]
  role_allowed_office_ids: string[]
  role_allowed_tag_ids: string[]
  office_id: string
  office_name: string
  country_id: string
  country_code: string
  language_code: string
  language_codes: string[]
  international_toggle: string
  preferred_download_format: string
  masterdata_languages: string[]
  expand_proposals_toggle: boolean
  selected_office_ids: string[]
  include_officeless_reference_projects: boolean
  selected_tag_ids: string[]
  override_language_code: string | null
  default_cv_template_id: string
  image: {
    url: string | null
    thumb: {
      url: string | null
    }
    fit_thumb: {
      url: string | null
    }
    large: {
      url: string | null
    }
    small_thumb: {
      url: string | null
    }
  }
  name: string
  telephone: string
  default_cv_id: string

  constructor(data: Partial<FlowcaseUser> | null = null) {
    if (data) {
      Object.assign(this, data)
    }
  }
}
