import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import { ImageWithFallback } from "@/utils/ImageWithFallback"
import { ICell } from "../../Cell"
import { useUser } from "@/modules/flowcase/hooks/useUser"
import { StatusCode } from "@/core/generated/api/enums/status_code"

export const ProfileImageCell = (props: ICell) => {
  const cvpId = props.record?.cvp_id
  const {
    data: user,
    loading: isLoading,
    code,
  } = useUser(props.record?.type === "INTERNAL" ? cvpId : null)

  if ((code !== StatusCode.Ok || !user) && !isLoading) {
    return <span className="text-gray-200">No profile image</span>
  }

  if (isLoading) {
    return <Skeleton className="h-12 w-12 rounded-full" />
  }

  return (
    <div className="flex h-full w-full justify-center">
      <div
        style={user?.image?.thumb?.url ? { overflow: "hidden" } : {}}
        className="relative h-12 w-12 rounded-full"
      >
        <ImageWithFallback
          src={user.image.thumb.url}
          objectFit="cover"
          className="h-10 w-full max-w-[100px] object-cover"
          fill
        />
      </div>
    </div>
  )
}
