import { DropdownGeneric } from "@/shared/components/fields"
import { DropdownItem } from "@/shared/components/items/DropdownItem"
import {
  faCog,
  faEllipsis,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRouter } from "next/router"
import { ICell } from "../../Cell"

export const UserOptionsCell = ({ record }: ICell) => {
  const router = useRouter()

  return (
    <DropdownGeneric
      collection={[
        {
          title: "Manage",
          onClick: () => router.push(`/people/${record.uid}`),
          icon: faCog,
        },
      ]}
      renderItem={({ item, index }: { item: any; index: number }) => (
        <DropdownItem key={index} selected {...item} />
      )}
      button={
        <FontAwesomeIcon
          id="options-btn"
          className="cursor-pointer"
          size="xl"
          icon={faEllipsis}
        />
      }
      placement="left"
    />
  )
}
