import { FetchClient } from "@/core/client/fetch_client"
import { StatusCode } from "@/core/generated/api/enums/status_code"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { getService } from "hooks/useStartup"
import { useEffect, useState } from "react"
import { FlowcaseUser } from "../models/flowcase_user"

export function useUser(flowcaseId: string | null) {
  const [data, setData] = useState<FlowcaseUser>(new FlowcaseUser())
  const [loading, setLoading] = useState<boolean>(true)
  const [code, setCode] = useState<number>(StatusCode.Ok)
  const [message, setMessage] = useState<string>("")

  const client: FetchClient = getService(FetchClient)

  useEffect(() => {
    if (flowcaseId) {
      fetchUser()
    }
  }, [flowcaseId])

  const fetchUser = async () => {
    setLoading(true)
    let response: ResponseModel | null = null

    try {
      response = await client.getAsync(`/flowcase/users/${flowcaseId}`)
      if (response !== null) {
        const model: FlowcaseUser =
          ResponseSerializer.deserialize<FlowcaseUser>(response)
        if (model !== null) {
          setData(model)
          setCode(response.code)
        } else {
          setCode(StatusCode.NoContent)
        }
      } else {
        setCode(StatusCode.BadRequest)
      }
    } catch (error) {
      console.error("Failed to fetch user", error)
      setCode(StatusCode.BadRequest)
    } finally {
      setMessage(response?.message)
      setLoading(false)
    }
  }

  return { data, loading, code, message }
}
